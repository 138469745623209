import React, { Component } from 'react';
import { PrimaryButton, SecondaryButton } from 'spheracloud-common';
import './WizardPageFooter.scss';
import { connect } from 'react-redux';
import { submitForm, showCancelForm } from '../../actions/appActions';
import { goToNextPageBegin, goToPreviousPage, goToPreviousSubPage, addSubPageObject, clearSubPage, updateSubPageObject, goToNextSubPageBegin } from '../../actions/wizardActions';
import Strings from '../../constants/Strings';
import { getCurrentParentPage, getPreviousPageTitle, getPreviousSubPageTitle, getIsLastSubPage, getSubPageElementGUID } from '../../selectors/wizardSelectors';
import { ArrowLeft } from '../../icons';
import Translation from '../Translation/Translation';
import { getString } from '../../selectors/appSelectors';

class WizardPageFooter extends Component {

    nextButtonClick() {
        const { nextPage, nextSubPage, selectedSubPage } = this.props;

        if (selectedSubPage > -1) {
            nextSubPage();
        } else {
            nextPage();
        }
    }

    previousButtonClick() {
        const { selectedPage, previousPage, previousSubPage, showCancelForm, clearSubPage, selectedSubPage } = this.props;

        if (selectedPage > 0) {
            if (selectedSubPage > 0) {
                previousSubPage();
            } else if (selectedSubPage === 0) {
                clearSubPage();
            } else {
                previousPage();
            }
        } else {
            showCancelForm();
        }
    }

    getNextButtonText() {
        const { selectedSubPage, isLastSubPage } = this.props;
        return <Translation>{selectedSubPage > -1 && isLastSubPage ? Strings.done : Strings.next}</Translation>
    }

    getPreviousButtonText(isMobile) {
        const { page, selectedSubPage, previousPageTitle, previousSubPageTitle, untitledPage } = this.props;
        if (isMobile) {
            return <Translation>{Strings.back}</Translation>;
        } else if (selectedSubPage > 0) {
            return <><Translation>{Strings.back}</Translation>: {previousSubPageTitle ? previousSubPageTitle : untitledPage}</>;
        } else if (selectedSubPage === 0) {
            return <><Translation>{Strings.back}</Translation>: {page.MenuLabel}</>;
        } else {
            return <><Translation>{Strings.back}</Translation>: {previousPageTitle ? previousPageTitle : untitledPage}</>;
        }
    }

    render() {
        return (
            <>
                <div className="WizardPageFooter">
                    <SecondaryButton className="WizardPagePrevious" onClick={() => { this.previousButtonClick() }}>
                        <span className="WizardPagePreviousIcon">
                            <img src={ArrowLeft} alt="Back" />
                        </span>
                        <span className="WizardPagePreviousText">
                            {this.getPreviousButtonText(false)}
                        </span>
                    </SecondaryButton>
                    <PrimaryButton className="WizardPageNext" onClick={() => { this.nextButtonClick() }}>
                        {this.getNextButtonText()}
                    </PrimaryButton>
                </div>
                <div className="WizardPageFooterMobile">
                    <SecondaryButton className="WizardPagePrevious" onClick={() => { this.previousButtonClick() }}>
                        {this.getPreviousButtonText(true)}
                    </SecondaryButton>
                    <PrimaryButton className="WizardPageNext" onClick={() => { this.nextButtonClick() }}>
                        {this.getNextButtonText()}
                    </PrimaryButton>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedPage: state.wizardReducer.selectedPage,
        page: getCurrentParentPage(state),
        previousPageTitle: getPreviousPageTitle(state),
        previousSubPageTitle: getPreviousSubPageTitle(state),
        selectedSubPage: state.wizardReducer.selectedSubPage,
        selectedSubPageObject: state.wizardReducer.selectedSubPageObject,
        isLastSubPage: getIsLastSubPage(state),
        untitledPage: getString(Strings.untitledPage, state),
        subPageElementGUID: getSubPageElementGUID(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    nextPage: () => {
        dispatch(goToNextPageBegin());
    },
    previousPage: () => {
        dispatch(goToPreviousPage());
    },
    nextSubPage: () => {
        dispatch(goToNextSubPageBegin());
    },
    previousSubPage: () => {
        dispatch(goToPreviousSubPage());
    },
    clearSubPage: () => {
        dispatch(clearSubPage());
    },
    addSubPageObject: elementId => {
        dispatch(addSubPageObject(elementId));
    },
    updateSubPageObject: (elementId, index) => {
        dispatch(updateSubPageObject(elementId, index));
    },
    showCancelForm: () => {
        dispatch(showCancelForm());
    },
    submitForm: () => {
        dispatch(submitForm());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(WizardPageFooter);