import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable'
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './containers/App/App';
import Login from './containers/Login/Login';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { OidcProvider, loadUser, processSilentRenew } from 'redux-oidc';
import rootReducer from './reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HttpClient from './utils/httpClient';
import getUserManager from './utils/userManager';
import ConfigService from './services/ConfigService';
import DataService from './services/DataService';
import StorageService from './services/StorageService';
import LocationService from './services/LocationService';
import ValidationService from './services/ValidationService';
import AttributeService from './services/AttributeService';
import AzureServices from './services/AzureServices';
import PageService from './services/PageService';
import { loadAppSettings } from './actions/appActions';
import { getAppSettings } from './selectors/appSelectors';
import AuthService from './services/AuthService';
import Callback from './components/Auth/Callback/Callback';
import Logout from './components/Auth/Logout/Logout';
import { recoverReducerState, persistReducerState } from './utils/statePersistence';
import LoginBasicAuth from './containers/LoginBasicAuth/LoginBasicAuth';
import Cookies from 'universal-cookie';

const middleware = [
    ReduxThunk,
    StorageService,
    ConfigService,
    DataService,
    AuthService,
    LocationService,
    ValidationService,
    AttributeService,
    PageService,
    AzureServices,
];

const fetchAppSettings = async (store) => {
    const httpClient = new HttpClient(store.getState());
    return httpClient.get(`${process.env.PUBLIC_URL}/config/appsettings.json`).then(res => {
        store.dispatch(loadAppSettings(res.data));
    });
}

async function renderApp() {
    const initialState = recoverReducerState();
    const store = createStore(rootReducer, initialState, process.env.REACT_APP_SHOW_DEV_TOOLS == 1 ? composeWithDevTools(applyMiddleware(...middleware)) : applyMiddleware(...middleware));

    window.addEventListener("unload", () => {
        persistReducerState(store.getState());
    });

    await fetchAppSettings(store);

    const userManager = getUserManager(getAppSettings(store));
    loadUser(store, userManager).then(user => {

    ReactDOM.render(
        <Provider store={store}>
            <OidcProvider store={store} userManager={userManager}>
                <Router basename={process.env.PUBLIC_URL}>
                    <Switch>
                        <Route path="/:anonGuid/:formGroup/login" component={Login} />
                        <Route path="/:anonGuid/:formGroup/loginbasic" component={LoginBasicAuth} />
                        <Route path="/:anonGuid/:formGroup" component={App} />
                        <Route path="/callback" component={Callback} />
                        <Route path="/logout" component={Logout} />
                        <Route path="/" component={App} />
                    </Switch>
                </Router>
            </OidcProvider>
        </Provider>,
        document.getElementById('root')
    )});
}

async function handleSilentRefresh() {
    const cookies = new Cookies();
    const originator = cookies.get('fr-originator');

    if (originator) {
        const initialState = recoverReducerState();
        const store = createStore(rootReducer, initialState, process.env.REACT_APP_SHOW_DEV_TOOLS == 1 ? composeWithDevTools(applyMiddleware(...middleware)) : applyMiddleware(...middleware));

        await fetchAppSettings(store);
        const appSettings = getAppSettings(store);
   
        cookies.remove('fr-originator', { path: "/", domain: `.${appSettings.domain}` });
        window.location.hostname = originator;
    }
    else {
        processSilentRenew();
    } 
}

if (window.location.pathname.indexOf('silent-refresh') > -1) {
    handleSilentRefresh();
} else {
    renderApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
