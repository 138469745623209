import React, { Component } from 'react';
import './ContinueDraft.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal, Spinner } from 'spheracloud-common';
import { hideContinueDraft, continueDraft, clearDraftFailure, continueDraftRemoveData } from '../../actions/appActions';
import { deleteDraftRecord } from '../../actions/wizardActions';
import { getString, getSavedAccidents, isAuthenticated } from '../../selectors/appSelectors';
import Strings from '../../constants/Strings';
import ContinueDraftInput from './ContinueDraftInput/ContinueDraftInput'

class ContinueDraft extends Component {
    constructor(props) {
        super(props);

        this.state = {
            enteredDraftCode: "",
            isShortCodeValid: true
        };

        props.continueDraftRemoveData();

        this.submitDraft = this.submitDraft.bind(this);
        this.checkShortCode = this.checkShortCode.bind(this);
        this.selectOption = this.selectOption.bind(this);
        this.deleteRecord = this.deleteRecord.bind(this);
    }

    selectOption (text){
        this.setState({ isShortCodeValid: true, enteredDraftCode: text && text.trim()})
    }

    deleteRecord(shortcode){
        const { deleteDraftRecord } = this.props

        deleteDraftRecord(shortcode)

    }

    submitDraft() {
        const { continueDraft, history } = this.props;
        const { enteredDraftCode } = this.state;

        if (this.checkShortCode(enteredDraftCode)) {
            this.setState({ isShortCodeValid: true, enteredDraftCode: ""  });
            continueDraft(enteredDraftCode.toUpperCase(), history);
        } else {
            this.setState({ isShortCodeValid: false });
        }

    }

    checkShortCode(str) {
        const format = /^[a-zA-Z0-9]{5}$/;
        return format.test(str);
    }

    render() {
        const {
            isContinuingDraft, continueDraftErr, hideContinueDraft, clearDraftFailure,
            okLabel, continueDraftTitle, continueDraftLabel, draftCodePlaceholderLabel,
            genericDraftErrorLabel, errorLabel, cancelLabel, draftCodeError, savedAccidents,
            selectedLanguage, isUserAuthenticated, savedAccidentsDeleteLoading
        } = this.props;

        const { isShortCodeValid, enteredDraftCode } = this.state;
        if (continueDraftErr) {
            return (
                <Modal
                    className="ContinueDraftModal"
                    title={errorLabel}
                    apply={okLabel}
                    cancel={""}
                    onApply={() => clearDraftFailure()}
                    onCancel={() => clearDraftFailure()}
                >
                    <span>{continueDraftErr || genericDraftErrorLabel}</span>
                </Modal>
            );
        }

        if (isContinuingDraft) {
            return (
                <Modal
                    className="ContinueDraftModal"
                    title={continueDraftTitle}
                    apply={okLabel}
                    cancel={""}
                    onCancel={() => { }}
                    disabled={true}
                >
                    <Spinner />
                </Modal>
            );
        }

        return (
            <Modal
                className="ContinueDraftModal"
                title={continueDraftTitle}
                onApply={() => this.submitDraft()}
                onCancel={hideContinueDraft}
                cancel={cancelLabel}
                apply={okLabel}
                disabled={savedAccidentsDeleteLoading}
            >
                <div className="ContinueDraftModalDescription">{continueDraftLabel}</div>
                <div className="InvalidShortCodeWarning">
                    {
                        !isShortCodeValid && <>{' * '}{draftCodeError}</>
                    }
                </div>
                <ContinueDraftInput 
                    hasError={!isShortCodeValid}
                    savedAccidents={savedAccidents}
                    placeholder={draftCodePlaceholderLabel}
                    onTextChanged={(text) => this.selectOption(text)}
                    onDeleteRecord={(shortcode) => this.deleteRecord(shortcode)}
                    onKeyPress={(e) => { if (e.charCode === 13) { this.submitDraft() } }}
                    value={enteredDraftCode}
                    selectedLanguage={selectedLanguage}
                    isUserAuthenticated={isUserAuthenticated}
                    savedAccidentsDeleteLoading={savedAccidentsDeleteLoading}
                />
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    const { appReducer } = state;

    return {
        isContinuingDraft: appReducer.isContinuingDraft,
        continueDraftErr: appReducer.errors.continueDraft,
        okLabel: getString(Strings.ok, state),
        cancelLabel: getString(Strings.cancel, state),
        errorLabel: getString(Strings.error, state),
        continueDraftTitle: getString(Strings.continueDraftTitle, state),
        continueDraftLabel: getString(Strings.continueDraftMessage, state),
        draftCodePlaceholderLabel: getString(Strings.draftCodePlaceholder, state),
        genericDraftErrorLabel: getString(Strings.genericDraftError, state),
        draftCodeError: getString(Strings.draftCodeError, state),
        savedAccidents: getSavedAccidents(state),
        selectedLanguage: state.appReducer.userSettings.language,
        isUserAuthenticated: isAuthenticated(state),
        savedAccidentsDeleteLoading: state.appReducer.savedAccidentsDeleteLoading,
    }
}

const mapDispatchToProps = dispatch => ({
    hideContinueDraft: () => dispatch(hideContinueDraft()),
    continueDraft: (code, history) => dispatch(continueDraft(code, history)),
    continueDraftRemoveData: () => dispatch(continueDraftRemoveData()),
    clearDraftFailure: () => dispatch(clearDraftFailure()),
    deleteDraftRecord: (shortcode) => dispatch(deleteDraftRecord(shortcode))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContinueDraft));