import * as AppActions from '../actions/appActions';
import * as AuthActions from '../actions/authActions';
import * as WizardActions from '../actions/wizardActions';
import { USER_EXPIRING, SILENT_RENEW_ERROR, loadUser } from 'redux-oidc';
import getUserManager from '../utils/userManager';
import { getAppSettings, getAnonGuid, getString, getFormGroup, getSSOUrl, getProfileValue, getActiveLocation, getSelectedFormTypeId, isVanityUrl } from '../selectors/appSelectors';
import HttpClient from '../utils/httpClient';
import endpoints from '../constants/Endpoints';
import strings from '../constants/Strings';
import { persistReducerState } from '../utils/statePersistence';
import { USER_TYPES } from '../constants/UserTypes';
import { POLICY_TYPES, LOGIN_ERROR_CODES, LOGIN_ERROR_MESSAGES } from '../constants/AuthConstants';
import Cookies from 'universal-cookie';

const setAuthType = (store, next, userTypeSelected, history) => {
    next(WizardActions.setUserType(userTypeSelected));
    const state = store.getState();

    switch (userTypeSelected) {
        case USER_TYPES.ANONYMOUS:
        case USER_TYPES.MANUAL:
            logoutUserIfExists(store, next);
            break;
        case USER_TYPES.AUTHENTICATED:
            next(AppActions.clearBasicAuthLoginError());
            history.push(`/${getAnonGuid(state)}/${getFormGroup(state)}/loginbasic`);
            break;
        case USER_TYPES.SSOAUTHENTICATED:
            loginUserWithSSO(store, next);
            break;
        case USER_TYPES.B2CAUTHENTICATED:
            loginUser(store, next);
            break;
        case USER_TYPES.B2CAUTHENTICATED_WITH_DOMAIN:
            const domainHint = state.appReducer.domainHint;
            loginUser(store, next, domainHint);
            break;
        default:
            logoutUserIfExists(store, next);
    }
}

const setVanityUrlCookie = (store) => {
    const appSettings = getAppSettings(store);
    const cookies = new Cookies();

    if (isVanityUrl(store.getState())) {
        cookies.set('fr-originator', window.location.host, { path: "/", domain: `.${appSettings.domain}` });
    }
    else {
        cookies.remove('fr-originator', { path: "/", domain: `.${appSettings.domain}` });
    }
}

const loginUserBasicAuth = async (store, next, basicAuth) => {
    next(AppActions.basicAuthLoginBegin());
    try {
        const state = store.getState();
        const httpClient = new HttpClient(state, { basicAuth: basicAuth });
        const appSettings = getAppSettings(store);
        const anonGuid = getAnonGuid(state);

        const loginResponse = await httpClient.get(endpoints.loginBasicAuth(appSettings, anonGuid));
        const { data: token, headers } = loginResponse;
        const { loginresult } = headers;

        if ((loginresult && loginresult !== "-1") || token === '00000000-0000-0000-0000-000000000000') {
            next(AppActions.basicAuthLoginFailure('Login failed'))
        } else {
            const userManager = getUserManager(appSettings);
            await userManager.removeUser();

            next(AppActions.basicAuthLoginSuccess());

            persistReducerState(store.getState());

            userManager.signinRedirect({
                extraQueryParams: { token: token }
            });
        }
    } catch (ex) {
        next(AppActions.basicAuthLoginFailure(ex))
    }
}

const loginUser = async (store, next, domainHint) => {
    next(AppActions.setFromSSO(false));
    const appSettings = getAppSettings(store);
    const userManager = getUserManager(appSettings);

    await userManager.removeUser();

    persistReducerState(store.getState());
    setVanityUrlCookie(store);

    const signInParams = domainHint ? { extraQueryParams: { domain_hint: domainHint } } : {};

    userManager.signinRedirect(signInParams);
}

const loginUserWithSSO = (store, next) => {
    const state = store.getState();
    const url = getSSOUrl(state);

    persistReducerState(state);

    window.location.href = url;
}

const loginUserWithToken = async (store, next, token) => {
    next(WizardActions.setUserType(USER_TYPES.SSOAUTHENTICATED));
    next(AppActions.setFromSSO(true));

    const appSettings = getAppSettings(store);
    const userManager = getUserManager(appSettings);

    await userManager.removeUser();

    persistReducerState(store.getState());

    userManager.signinRedirect({
        extraQueryParams: { token: token }
    });
}

const logoutUserIfExists = async (store, next) => {

    let state = store.getState();
    const fromSSO = state.appReducer.fromSSO;
    const logoutURL = getProfileValue('Company.Interface.FirstReportCustomLogoutPage', state);
    next(AppActions.setFromSSO(false));

    if (state.wizardReducer.userTypeSelected !== USER_TYPES.MANUAL || state.auth.user) {
        next(AppActions.setAnonymousUser());
    }

    state = store.getState();
    if (state.auth.user) {
        const appSettings = getAppSettings(store);
        const userManager = getUserManager(appSettings);

        await userManager.removeUser();

        persistReducerState(state);

        if (fromSSO && logoutURL) {
            window.location.href = logoutURL;
        } else {
            setVanityUrlCookie(store);

            userManager.signoutRedirect({
                id_token_hint: state.auth.user.id_token
            });
        }
    }
}

const verifyUserAndLoadData = async (store, next, history) => {
    const appSettings = getAppSettings(store);
    const userManager = getUserManager(appSettings);
    const anonGuid = getAnonGuid(store.getState());
    next(AppActions.clearLocationRestrictionUserError());

    try {
        const state = store.getState();
        const { user } =  state.auth;

        if(user) {
            const httpClient = new HttpClient(state);
            const response = await httpClient.get(endpoints.verifyUser(appSettings, anonGuid));
            const { UserID, UserGUID, Username, UserCurrentLocation } = response.data;

            const currentLocationId = UserCurrentLocation && parseInt(UserCurrentLocation);

            next(AppActions.setAuthInfo({ userId: UserID, userGuid: UserGUID, name: Username, currentLocation: currentLocationId }));

            const selectedFormTypeId = getSelectedFormTypeId(state);
            const activeLocation = getActiveLocation(state);
            if (activeLocation) {
                store.dispatch(AppActions.clearActiveLocation());
                store.dispatch(AppActions.setActiveLocationId(activeLocation.nodeExternalId))
            }
            else if (currentLocationId && (!selectedFormTypeId || selectedFormTypeId === -1)) {
                store.dispatch(AppActions.setActiveLocationId(currentLocationId));
            }
        }
        else {
            next(AppActions.setFromSSO(false))
            next(AppActions.setAuthInfo({}));
        }

        store.dispatch(AppActions.loadData());
        store.dispatch(WizardActions.translationLanguagesListBegin());
    }
    catch (error) {
        next(AppActions.showAuthError(history, getString(strings.noAccessToCompany, store.getState())));

        persistReducerState(store.getState());
        setVanityUrlCookie(store);

        userManager.signoutRedirect();
    }
}

const redirectUserToForm = (store, next, history) => {

    const appSettings = getAppSettings(store);
    const userManager = getUserManager(appSettings);
    loadUser(store, userManager).then(user => {
        const url = getRedirectToFormUrl(store.getState());
        if (!url) {
            next(AppActions.showAuthError('Could not recover selected form during redirect.'));
        }
        history.push(url);
    })
}

const redirectUserToError = (store, next, history, error) => {
    const cookies = new Cookies();
    const originator = cookies.get('fr-originator');

    if (originator) {
        const appSettings = getAppSettings(store);
        cookies.remove('fr-originator', { path: "/", domain: `.${appSettings.domain}` });
        window.location.hostname = originator;
    }
    else {
        if (error && error.error_description && error.error_description.startsWith(LOGIN_ERROR_CODES.FORGOT_PASSWORD)) {
            const appSettings = getAppSettings(store);
            const userManager = getUserManager(appSettings, POLICY_TYPES.FORGOT_PASSWORD);
    
            persistReducerState(store.getState());
            setVanityUrlCookie(store);
    
            userManager.signinRedirect({ });
            return;
        }
    
        const forgotPasswordCancelled = error && error.toString() === LOGIN_ERROR_MESSAGES.USER_CANCELLED_FLOW;
        if (!forgotPasswordCancelled) {
            next(AppActions.showAuthError(error));
        }

        const url = getRedirectToFormUrl(store.getState());
        if (!url) {
            history.push("/");
        }
    
        history.push(url);
    }
}

const getRedirectToFormUrl = (state) => {
    const formGroup = getFormGroup(state);
    const anonGuid = getAnonGuid(state);

    if (!formGroup || !anonGuid) {
        return undefined
    }

    return `/${anonGuid}/${formGroup}`;
}

const userExpiring = (store) => {
    setVanityUrlCookie(store);
}

const silentRenewFailed = (store) => {
    const appSettings = getAppSettings(store);
    const cookies = new Cookies();
    cookies.remove('fr-originator', { path: "/", domain: `.${appSettings.domain}` });
}

const AuthService = store => next => (action) => {
    next(action);
    switch (action.type) {
        case AuthActions.LOGIN_USER:
            loginUser(store, next, action.email);
            break;
        case AuthActions.VERIFY_USER_AND_LOAD_DATA:
            verifyUserAndLoadData(store, next, action.history);
            break;
        case AuthActions.LOGOUT_USER:
            logoutUserIfExists(store, next);
            break;
        case AuthActions.USER_SUCCESS_LOGIN:
        case AuthActions.USER_LOGGED_OUT:
            redirectUserToForm(store, next, action.history);
            break;
        case AuthActions.USER_FAILED_LOGIN:
            redirectUserToError(store, next, action.history, action.error);
            break;
        case AuthActions.SET_AUTH_TYPE:
            setAuthType(store, next, action.userType, action.history);
            break;
        case AuthActions.LOGIN_USER_WITH_TOKEN:
            loginUserWithToken(store, next, action.token);
            break;
        case AuthActions.LOGIN_USER_BASIC_AUTH:
            loginUserBasicAuth(store, next, action.basicAuth);
            break;
        case USER_EXPIRING:
            userExpiring(store);
            break;
        case SILENT_RENEW_ERROR:
            silentRenewFailed(store);
        default:
            break;
    }
};

export default AuthService;
