import React from 'react';
import './HierarchyLocationSelector.scss';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getAppSettingsFromState, getAuthInfo, isAuthenticated, getString } from '../../../selectors/appSelectors';
import { HierarchyLookup, LookupService } from 'spheracloud-hierarchy';
import endpoints from '../../../constants/Endpoints';
import strings from '../../../constants/Strings';

class HierarchyLocationSelector extends React.Component {
    constructor(props) {
        super(props);

        const { initialLocation, onChange } = props;
        
        this.handleData = this.handleData.bind(this);
        this.notifyHeightChange = this.notifyHeightChange.bind(this);        

        /* when auto populated, get the node details for display in the review page */
        if (_.isObject(initialLocation) && _.size(initialLocation) === 1 && initialLocation.nodeExternalId) {
            const service = this.getHierarchyService();
            service.getLocationById(initialLocation.nodeExternalId).then((data) => {
                onChange && onChange(data);
            });
        }
    }

    getHierarchyService() {
        const { hierarchyApiUrl, anonGuid, token, authInfo, hierarchyId, selectedHierarchyId, hierarchyEnvironment, initialLocation, selectableTypes, forAdmin } = this.props;
        const userGuid = (authInfo && authInfo.userGuid) || anonGuid;

        const apiSettings = {
            apiRoot: `${hierarchyApiUrl}`,
            userGuid,
            token,
            hierarchyEnvironment,
        }

        const lookupService = new LookupService(apiSettings);
        const usedHierarchyId = hierarchyId ? hierarchyId : selectedHierarchyId;

        this.handleData = this.handleData.bind(this);
        this.notifyHeightChange = this.notifyHeightChange.bind(this);

        return {
            getRootNodes: () => {
                return lookupService.getRootNodes(usedHierarchyId, forAdmin === "1").then((nodes) => {
                    this.notifyHeightChange();
                    return nodes;
                });
            },
            getChildNodes: (nodeid, take, skip) => {
                return lookupService.getChildNodes(usedHierarchyId, nodeid, take, skip, undefined, forAdmin === "1").then((nodes) => {
                    this.notifyHeightChange();
                    return nodes;
                });
            },
            searchNodes: (startNodeId, searchFor, take, skip) => {
                return lookupService.searchNodes(usedHierarchyId, startNodeId, searchFor, take, skip, undefined, forAdmin === "1").then((nodes) => {
                    this.notifyHeightChange();
                    return nodes;
                });
            },
            getLocationById: (nodeId) => {
                if (_.isObject(initialLocation) && _.size(initialLocation) > 1 && initialLocation.nodeExternalId === nodeId) {
                    return initialLocation;
                } else {
                    return lookupService.getLocationNode(usedHierarchyId, nodeId);
                }
            },
            nodeSelectable: (node) => {
                return !selectableTypes ||
                    selectableTypes.includes(node.nodeType);
            },
            getNodeById: (nodeId) => {
                return lookupService.getNode(usedHierarchyId, nodeId);
            }
        };
    }

    handleData(data) {
        const { onChange } = this.props;
        onChange && onChange(data);
    }

    notifyHeightChange() {
        const { onHeightChange } = this.props;
        // setTimeout 0 used so that it's called after element has been redrawn
        setTimeout(() => {
            onHeightChange && onHeightChange();
        }, 0);
    }

    render() {
        const { initialLocation, hasError, disabled, selected, showMore, showAllSelected, showLess, search, selectLocation, startNodeId } = this.props;
        let classes = 'HierarchyLocationSelectorContainer';

        if (hasError) {
            classes = `${classes} HierarchyLocationSelectorError`;
        }

        if (disabled) {
            classes = `${classes} HierarchyLocationSelectorDisabled`;
        }

        return (
            <div className={classes}>
                {
                    disabled ?
                    (
                        <>
                            <span className="HierarchyLocationSelectorDisabledNodeName">{initialLocation && initialLocation.nodeName}</span>
                            <span className="HierarchyLocationSelectorDisabledPath">
                                {
                                    initialLocation && initialLocation.path && (
                                        " " + initialLocation.path
                                            .filter((hierarchyLocation, index) => { return !(index === initialLocation.path.length - 1 && hierarchyLocation.nodeName === initialLocation.nodeName) })
                                            .map(hierarchyLocation => {
                                                return hierarchyLocation.nodeName;
                                            })
                                            .join(' / ')
                                    )
                                }
                            </span>
                        </>
                    )
                    :
                        <HierarchyLookup
                            locationId={initialLocation && initialLocation.nodeExternalId}
                            onValueChange={this.handleData}
                            {...this.props}
                            service={this.getHierarchyService()}
                            labels={{
                                selectedText: selected,
                                showMoreText: showMore,
                                showAllSelectedText: showAllSelected,
                                showLessText: showLess,
                                searchPlaceHolder: search,
                                selectLocationPlaceHolder: selectLocation
                            }}
                            startNodeId={startNodeId}
                            />
                }

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        hierarchyApiUrl: endpoints.getHierarchyAPIUrl(getAppSettingsFromState(state), isAuthenticated(state)),
        anonGuid: state.appReducer.anonGuid,
        token: state.auth.user ? state.auth.user.access_token : null,
        authInfo: getAuthInfo(state),
        selectedHierarchyId: state.appReducer.selectedHierarchyId,
        hierarchyEnvironment: state.appReducer.hierarchyEnvironment,
        selectLocation: getString(strings.selectLocation, state),
        selected: getString(strings.selected, state),
        showMore: getString(strings.showMore, state),
        showAllSelected: getString(strings.showAllSelected, state),
        showLess: getString(strings.showLess, state),
        search: getString(strings.search, state),
    };
};

export default connect(mapStateToProps)(HierarchyLocationSelector);