import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import localforage from 'localforage';
import { getAnonGuid, getAuthInfo } from '../selectors/appSelectors';

function getConfigHeaders(state, forceAnonymous, basicAuth, skipHttpCache=true) {
    const token = (!forceAnonymous && state && state.auth.user) ? state.auth.user.access_token : null;
    const authInfo = getAuthInfo(state);
    const userGuid = (!forceAnonymous && authInfo && authInfo.userGuid) || getAnonGuid(state);

    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
    };

    if (userGuid) {
        config.headers['Sphera-User-Guid'] = userGuid;
    }

    if (skipHttpCache) {
        config.headers['Pragma'] = 'no-cache';
    }

    if (basicAuth) {
        config.headers.Authorization = `Basic ${basicAuth}`;
        config.headers.isMobile = 'true';
    } else if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}

export default class HttpClient {
    constructor(state, options = {}) {
        const forceAnonymous = options.forceAnonymous || false;
        const basicAuth = options.basicAuth;
        const skipHttpCache = options.skipHttpCache;

        this.config = getConfigHeaders(state, forceAnonymous, basicAuth, skipHttpCache);
        this.initializeCache = this.initializeCache.bind(this);
        this.constructedCancelTokenSource = axios.CancelToken.source();
    }

    get cancelTokenSource() {
        return axios.CancelToken.source();
    }

    get(url, responseType, cache, cancelTokenSource) {
        const settedCache = cache ? setupCache(this.initializeCache(cache)) : undefined;
        const api = axios.create({
            ...this.config,
            responseType,
            adapter: cache ? settedCache.adapter : undefined
        });
        return api.get(url, {cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined});
    }

    post(url, data, responseType) {
        const config = {
            ...this.config,
            responseType
        };
        return axios.post(url, data, config);
    }

    put(url, data) {
        const config = { ...this.config };
        return axios.put(url, data, config);
    }

    patch(url, data) {
        const config = { ...this.config };
        return axios.patch(url, data, config);
    }

    delete(url) {
        const config = { ...this.config };
        return axios.delete(url, config);
    }

    initializeCache(cacheName) {
        const store = localforage.createInstance({
            driver: [
                localforage.INDEXEDDB
            ],
            name: cacheName
        })

        return {
            maxAge: 24 * 60 * 60 * 1000,
            store
        };
    }
}
